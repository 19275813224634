import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token:sessionStorage.getItem('token'),
    userInfo:JSON.parse(sessionStorage.getItem('userInfo')),
    defaultIndex:sessionStorage.getItem('defaultIndex')
  },
  getters: {
  },
  mutations: {
    setToken(state,data){//保存token
      state.token=data
      sessionStorage.setItem('token',data)
    },
    setUserInfo(state,data){
      state.userInfo=JSON.stringify(data)
      sessionStorage.setItem('userInfo',JSON.stringify(data))
    },
    setdDefaultIndex(state,data){
      state.defaultIndex=data
      sessionStorage.setItem('defaultIndex',data)
    }
  },
  actions: {
  },
  modules: {
  }
})
