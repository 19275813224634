import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  
  {
    path: '/',
    name: 'login',
    component: () => import('../views/LoginView.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/HomeView.vue'),
    children:[
      //商家管理
      {
        path: '/MerchantsList',
        name: 'MerchantsList',
        component: () => import('../views/MerchantsList.vue'),
      },
      {
        path: '/MerchantsTopUp',
        name: 'MerchantsTopUp',
        component: () => import('../views/MerchantsTopUp.vue'),
      },
      {
        path: '/couponList',
        name: 'couponList',
        component: () => import('../views/couponList.vue'),
      },
      {
        path: '/MerchantsNotice',
        name: 'MerchantsNotice',
        component: () => import('../views/MerchantsNotice.vue'),
      },
      {
        path: '/ContentAudit',
        name: 'ContentAudit',
        component: () => import('../views/ContentAudit.vue'),
      },
      {
        path: '/testUpload',
        name: 'testUpload',
        component: () => import('../views/testUpload.vue'),
      },
      {
        path: '/activeListAll',
        name: 'activeListAll',
        component: () => import('../views/activeListAll.vue')
      },
    ]
  },
 
  {
    path: '/activeList',
    name: 'activeList',
    component: () => import('../views/activeList.vue')
  },
  {
    path: '/materialList',
    name: 'materialList',
    component: () => import('../views/materialList.vue')
  },
  {
    path: '/shopList',
    name: 'shopList',
    component: () => import('../views/shopList.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  //base: process.env.BASE_URL,
  base:'admin',
  routes
})

export default router
